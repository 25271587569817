<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="filterStr"
              placeholder="로그인ID/이름"
              class="el-def"
              @keyup.enter="refetchData()"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              관리자 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refResultTable"
        primary-key="userLoginId"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelected"
      />
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="10"
        align="center"
      />
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="EditIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              상세
            </h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-primary"
              @click.stop="popupSearchAdAccount"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>신규 등록</span>
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click.stop="changeManagerRole"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>권한 변경</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click.stop="removeManagerRole"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>삭제</span>
            </b-button>
          </b-col>
        </b-row>
        <!-- Edit Form -->
        <validation-observer ref="formRules">
          <b-row class="mt-1">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="로그인ID"
                label-for="userLoginId"
              >
                <b-form-input
                  id="userLoginId"
                  v-model="managerData.userLoginId"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="이름"
                label-for="userName"
              >
                <b-form-input
                  id="userName"
                  v-model="managerData.userName"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="권한"
                label-for="userRole"
              >
                <validation-provider
                  #default="{ errors }"
                  name="권한"
                  rules="required"
                >
                  <v-select
                    v-model="managerData.userRole"
                    :options="codes.userRole"
                    placeholder="권한"
                    :reduce="option => option.code"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="등록일"
                label-for="createDateStr"
              >
                <b-form-input
                  id="createDateStr"
                  v-model="managerData.createDateStr"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </b-card>
    <search-ad-account
      :is-show="showSearchAdAccount"
      @close="searchAdAccount.close"
    />
    <!-- Textarea 형식 입력 팝업 -->
    <editor-popup
      :show-editor="showEditor"
      :text="editorText"
      :title="editorTitle"
      :description="editorDesc"
      @close="editorConfig.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import {
  ref, onMounted, watch, getCurrentInstance,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { errorFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditorPopup from '@/views/apps/popup/TextEditor.vue'
import SearchAdAccount from './SearchAdAccount.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    SearchAdAccount,
    EditorPopup,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const codes = ref({
      userRole: [],
    })

    // Query Data
    const filterStr = ref('')
    // Query Data End.

    // Main Table Config
    const beforeUserRole = ref(null)
    const dataInitState = {
      userLoginId: null,
      userName: null,
      userRole: null,
      createDateStr: null,
    }
    const managerData = ref({ ...dataInitState })

    const resetManagerData = () => {
      Object.assign(managerData.value, dataInitState)
      refs.formRules.reset()
    }

    const refResultTable = ref(null)
    const sortBy = ref('createDate')
    const isSortDesc = ref(true)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const dateFormatter = value => dayjs(new Date(value)).format('YY-MM-DD')
    const tableColumns = [
      { key: 'userLoginId', label: '관리자로그인ID', sortable: true },
      { key: 'userName', label: '이름', sortable: true },
      { key: 'roleName', label: '권한', sortable: true },
      {
        key: 'createDate', label: '등록일자', sortable: true, formatter: dateFormatter,
      },
    ]
    const refetchData = () => {
      refResultTable.value.refresh()
    }
    watch([currPage], () => {
      refetchData()
    })
    const onRowSelected = items => {
      const item = items[0]
      if (item) {
        getData(item.userId)
      }
    }
    // Main Table Config End.

    // Text Editor Popup 속성
    const editorKey = ref('')
    const editorText = ref('')
    const editorTitle = ref('')
    const editorDesc = ref('')
    const showEditor = ref(false)
    const editorConfig = {
      close: rs => {
        if (rs) {
          if (editorKey.value === 'changeManagerRole') updateManagerRole(rs)
          else if (editorKey.value === 'removeManagerRole') removeManager(rs)
        }
        showEditor.value = false
      },
    }
    const openEditor = (key, text, title, desc) => {
      editorKey.value = key
      editorText.value = text
      editorTitle.value = title
      editorDesc.value = desc
      showEditor.value = true
    }
    // Text Editor Popup 속성 End.

    const changeManagerRole = () => {
      if (!managerData.value.userLoginId) {
        pushToast('warning', '권한 변경 대상을 선택하세요.')
        return
      }

      if (beforeUserRole.value === managerData.value.userRole) {
        pushToast('warning', '변경하려는 권한이 기존과 동일합니다.')
        return
      }

      if (managerData.value.userRole === 9) {
        pushToast('warning', '시스템관리자 권한 변경은 허용되지 않습니다.')
        return
      }

      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        openEditor(
          'changeManagerRole',
          `empty${Math.random()}`,
          '권한 변경 사유 입력',
          '권한 변경 사유를 입력해 주세요. (*관리자 계정 적정성 검토 이력 보관용)',
        )
      })
    }

    const removeManagerRole = () => {
      if (!managerData.value.userLoginId) {
        pushToast('warning', '삭제할 대상을 선택하세요.')
        return
      }

      openEditor(
        'removeManagerRole',
        `empty${Math.random()}`,
        '관리자 계정 삭제 사유 입력',
        '관리자 계정 삭제 사유를 입력해 주세요. (*관리자 계정 적정성 검토 이력 보관용)',
      )
    }

    const showSearchAdAccount = ref(false)
    const searchAdAccount = {
      close: rs => {
        showSearchAdAccount.value = false
        if (!rs) {
          return
        }
        saveNewManager(rs)
      },
    }

    const popupSearchAdAccount = () => {
      showSearchAdAccount.value = true
    }

    const fetchCodes = () => {
      axios
        .get('/fa/common/manager/auth/codes')
        .then(rs => {
          const { managerUserRole } = rs.data
          codes.value.userRole = managerUserRole
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const fetchList = (ctx, callback) => {
      axios.post('/fa/common/manager/auth/list', {
        search: {
          filterStr: filterStr.value,
        },
        sort: {
          predicate: sortBy.value === 'userName'
            ? 'fullName'
            : sortBy.value === 'roleName'
              ? 'userRole'
              : sortBy.value,
          reverse: isSortDesc.value,
        },
        pagination: {
          number: currPage.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const getData = id => {
      axios.get(`/fa/common/manager/auth/${id}`)
        .then(rs => {
          beforeUserRole.value = rs.data.userRole
          managerData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const saveNewManager = param => {
      axios.post('/fa/common/manager/auth/new', {
        userLoginId: param.accountId,
        userName: param.userName,
        userRole: 7, // 운영관리자 권한으로 부여 (*상위 권한 부여시 등록 후 변경)
      })
        .then(() => {
          pushToast('success', '신규 관리자 등록 완료')
          refetchData()
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '신규 관리자 등록 과정에서 오류가 발생하였습니다.'))
        })
    }

    const updateManagerRole = description => {
      axios.patch('/fa/common/manager/auth/change-role', {
        userLoginId: managerData.value.userLoginId,
        userRole: managerData.value.userRole,
        description,
      })
        .then(() => {
          pushToast('success', '정보 변경 완료')
          refetchData()
          resetManagerData()
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '데이터를 변경하는 과정에서 오류가 발생하였습니다.'))
        })
    }

    const removeManager = description => {
      if (!managerData.value.userLoginId) {
        pushToast('warning', '삭제할 대상을 선택하세요.')
        return
      }
      axios.delete('/fa/common/manager/auth/remove', {
        data: {
          userLoginId: managerData.value.userLoginId,
          description,
        },
      })
        .then(() => {
          pushToast('success', '관리자 계정 삭제 완료')
          refetchData()
          resetManagerData()
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'))
        })
    }

    return {
      codes,
      filterStr,
      tableColumns,
      refResultTable,
      sortBy,
      isSortDesc,
      refetchData,
      currPage,
      totalRecords,
      managerData,
      resetManagerData,
      onRowSelected,

      required,
      fetchList,
      getData,
      removeManager,

      searchAdAccount,
      showSearchAdAccount,
      popupSearchAdAccount,

      editorKey,
      editorText,
      editorDesc,
      editorTitle,
      showEditor,
      editorConfig,
      openEditor,

      changeManagerRole,
      removeManagerRole,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
