<!-- [팝업] 닥터인포 관리자 계정 등록 -->
<template>
  <b-modal
    v-model="isShow"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="닥터인포 관리자 계정 신규 등록"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="m-1"
    >
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>대상자의 유비웨어ID, 이름(*유비웨어 주소록 표시 기준)을 입력하세요.</h5>
      </b-col>
    </b-row>
    <!-- <b-row
      class="mt-25"
    >
      <b-col
        cols="12"
        md="10"
        class="d-flex align-items-center justify-content-center"
      >
        <b-form-input
          v-model="query.accountId"
          class="el-def"
          placeholder="유비웨어 계정ID를 입력하세요"
          @keyup.enter="fetchData"
        />
      </b-col>
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="primary"
          class="btn-search"
          @click.prevent="fetchData"
        >
          조회
        </b-button>
      </b-col>
    </b-row> -->
    <validation-observer ref="formRules">
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="계정ID"
            label-for="accountId"
          >
            <validation-provider
              #default="{ errors }"
              name="계정ID"
              rules="required"
            >
              <b-form-input
                v-model="resultData.accountId"
                :state="errors.length > 0 ? false : null"
                @change="changeAccountId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="이름"
            label-for="userName"
          >
            <validation-provider
              #default="{ errors }"
              name="이름"
              rules="required"
            >
              <b-form-input
                v-model="resultData.userName"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="이메일"
            label-for="userPrincipalName"
          >
            <b-form-input
              v-model="resultData.userPrincipalName"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import axios from '@axios'
import { clone } from '@core/utils/utils'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    onMounted(() => {
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const resultInitState = {
      accountId: null,
      userName: null,
      userPrincipalName: null,
    }
    const resultData = ref({ ...resultInitState })

    const resetResultData = () => {
      Object.assign(resultData.value, resultInitState)
    }

    // Query Data
    const query = ref({
      accountId: '',
    })
    // Query Data End.

    const changeAccountId = () => {
      if (resultData.value.accountId) resultData.value.userPrincipalName = `${resultData.value.accountId}@ubcare.co.kr`
      else resultData.value.userPrincipalName = null
    }

    const fetchData = () => {
      resetResultData()

      if (!query.value.accountId) {
        pushToast('warning', '[유비웨어 계정 ID]는 필수 입력입니다.')
        return
      }
      axios.get(`/fa/common/manager/auth/search/${query.value.accountId}`)
        .then(rs => {
          resultData.value = rs.data
          console.log('result', resultData.value)
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '입력하신 ID로 일치하는 계정정보를 찾을 수 없습니다. 다시 확인해 주세요.'))
        })
    }

    const close = () => {
      emit('close', null)

      resetResultData()
      query.value.accountId = ''
    }

    const submit = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        emit('close', clone(resultData.value))

        resetResultData()
        query.value.accountId = ''
      })
    }

    return {
      required,
      query,
      close,
      submit,
      resultData,
      fetchData,
      changeAccountId,
    }
  },
}
</script>
